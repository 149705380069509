<template>
  <el-container class="homepage-sysconfig">
    <el-main>
      <std-nav :paths="navData"></std-nav>
      <el-card class="apply-form">
        <el-form ref="detail_form" class="form-jg5" label-width="140px" label-position="right" size="mini">
          <el-form-item label="CRM地址">
            <el-input v-model="form.PayPlat_CRMPath" placeholder=""></el-input>
          </el-form-item>
          <el-form-item label="">
            <el-checkbox v-model="form.PayPlat_WriteTextLog" true-label="1" false-label="0">启用回调文本日志</el-checkbox>
          </el-form-item>
          <el-form-item label="更新时间">
            <span v-text="form.PayPlat_LastModifyDateTime"></span>
          </el-form-item>
        </el-form>
        <el-divider content-position="left">支付账号</el-divider>
        <el-form ref="detail_form" class="form-jg5" label-width="140px" label-position="right" size="mini">
          <el-form-item label="微信商户号">
            <el-input v-model="form.PayPlat_WxMchID" class="w200" placeholder="微信支付的商户号"></el-input>
          </el-form-item>
          <el-form-item label="微信商圈商户ID">
            <el-input v-model="form.PayPlat_WxCircleMchID" placeholder="微信商圈商户ID,在服务商模式时填写,否则留空"></el-input>
          </el-form-item>
          <el-form-item label="微信商户ApiV3秘钥">
            <el-input v-model="form.PayPlat_WxApiV3Key" placeholder=""></el-input>
          </el-form-item>
          <el-form-item label="微信证书序列号">
            <el-input v-model="form.PayPlat_WxPayCertSerialNo" placeholder="证书的序列号"></el-input>
          </el-form-item>
          <el-form-item label="微信支付证书">
            <el-input v-model="form.PayPlat_WxPayCert" type="textarea" placeholder="证书的秘钥字符串"></el-input>
          </el-form-item>
          <el-form-item label="支付宝APPID">
            <el-input v-model="form.PayPlat_AliAppID" class="w200" placeholder="例:2014060600164699"></el-input>
          </el-form-item>
          <el-form-item label="签名方式">
            <el-select v-model="form.PayPlat_AliSignType" class="w200">
              <el-option label="RSA" value="RSA"></el-option>
              <el-option label="RSA2" value="RSA2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="支付宝公钥">
            <el-input v-model="form.PayPlat_AliPubKey" type="textarea" placeholder="公钥字符串"></el-input>
          </el-form-item>
          <el-form-item label="支付宝私钥">
            <el-input v-model="form.PayPlat_AliPriKey" type="textarea" placeholder="私钥字符串"></el-input>
          </el-form-item>
        </el-form>
      </el-card>
      <component v-if="dialog.show" :is="dialog.type" :data="dialog.data" @cancel="onCloseDialog" @success="dialog.event"></component>
    </el-main>
    <el-aside>
      <el-card class="action-box">
        <template #header>操作</template>
        <div style="">
          <el-button type="primary" size="mini" @click="onSave">
            <span>更新资料</span>
          </el-button>
          <div style="font-size: 13px;padding: 10px 0;color: #626161;">
            更新系统配置信息,请谨慎操作,将立即生效
          </div>
        </div>
      </el-card>
      <normal-card></normal-card>
    </el-aside>
  </el-container>
</template>
<script>
import NormalCard from '@/components/normal-card.vue';
export default {
  name: 'sysconfig',
  components: {
    NormalCard
  },
  data() {
    return {
      navData: [
        '系统设置'
      ],
      dialog: {
        show: false,
        type: '',
        data: {},
        event: null
      },
      form: {
        EB_APP_ID: '',
        EB_SHORT_NAME: '',
        EB_APP_NAME: '',
        EB_APP_GROUP_ID: '',
        EB_VERSION: '',
        EB_UI_URL: '',
        EB_API_URL: '',
        EB_STATE: 'Normal',
        EB_ICON: 'el-icon-s-custom',
        EB_CREATE_DATETIME: null,
        EB_LASTMODIFY_DATETIME: null,
        EB_HOME_PAGE: ''
      },
    }
  },
  computed: {
  },
  methods: {
    onSave() {
      let form = this.form;
      let postData = {
        config: {
          PayPlat_CRMPath: form.PayPlat_CRMPath,
          PayPlat_WriteTextLog: form.PayPlat_WriteTextLog,
          PayPlat_LastModifyDateTime: form.PayPlat_LastModifyDateTime,
          PayPlat_WxMchID: form.PayPlat_WxMchID,
          PayPlat_WxApiV3Key: form.PayPlat_WxApiV3Key,
          PayPlat_WxPayCert: form.PayPlat_WxPayCert,
          PayPlat_AliAppID: form.PayPlat_AliAppID,
          PayPlat_AliSignType: form.PayPlat_AliSignType,
          PayPlat_AliPubKey: form.PayPlat_AliPubKey,
          PayPlat_AliPriKey: form.PayPlat_AliPriKey,
          PayPlat_WxCircleMchID:form.PayPlat_WxCircleMchID,
          PayPlat_WxPayCertSerialNo:form.PayPlat_WxPayCertSerialNo

        }
      }
      this.Bus.ActionFunc('Sys-UpdatePayConfig', postData, data => {
        this.Bus.msg_success('更新成功')
      })
    },
    onCloseDialog() {
      this.dialog.show = false
    },
    onSetIcon() {
      let me = this
      this.$set(this, 'dialog', {
        show: true,
        type: 'win-select-icon',
        data: {},
        event(data) {
          me.form.EB_ICON = data.icon
          me.$set(me, 'dialog', {
            show: false
          })
        }
      })
    }
  },
  mounted() {
    let me = this
    this.Bus.ActionFunc('Sys-GetPayConfig', {
    }, data => {
      this.$set(this, 'form', data)
    })
  }
}
</script>
<style lang="scss">
.homepage-sysconfig {
  width: 1200px;
  margin: 0 auto;
  .isnocheck {
    color: #c4c4c4;
  }
  > .el-main {
    padding: 0px 20px 10px 0px !important;
    .w200 {
      width: 200px;
    }
  }
  > .el-aside {
    .action-box {
      min-height: 100px;
    }
  }
}
</style>